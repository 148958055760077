.container img {
  user-select: none;
}

.outline path  {
  stroke: #ffd10a;
}

.fill {
  fill: #ffd10a;
}

.empty {
  fill: #111
}

.opacity {
  animation: opacity 0.5s linear
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
}